import { createSlice } from "@reduxjs/toolkit";
import {
  getProjectLinksPortal,
  searchUrl,
  updatePageBuilderPortalData,
} from "../Thunks/pageBuilderPortalAction";

const initialState = {
  isLoading: false,
  pageBuilder: {
    projects: [],
    page_data: {
      first_page_url: null,
      last_page_url: null,
      next_page_url: null,
      prev_page_url: null,
      page_url: null,
      total_data: 0,
      per_page: 10,
      current_page: 1,
      from: 1,
      to: 0,
    },
    total_projects: 0,
    records_per_page: 10,
  },
  contactPageBuilder: {
    projects: [],
    paginationData: {},
  },
  page_data: {
    first_page_url: null,
    last_page_url: null,
    next_page_url: null,
    prev_page_url: null,
    page_url: null,
    total_data: 0,
    per_page: 10,
    current_page: 1,
    from: 1,
    to: 0,
  },
  selectedRows: [],
  templateList: [],
  pagination: {
    current: 1,
  },
  pageBuilderData: null,
  pageBuilderDataApi: {
    status: "idle",
    error: null,
  },
  draftPageBuilderData: {
    status: "idle",
    error: null,
  },
  tabs: [
    { name: "Mobile", color: "#4d8eff" },
    { name: "Tablet", color: "#4d8eff" },
    { name: "Desktop", color: "#4d8eff" },
  ],
  uiView: { name: "Mobile", color: "#4d8eff" },
  editView: "#editor_container",
  uiBlocksView: "default",
  uiBlocksIdex: null,
  selectedUiBlock: null,
  total_projects: null,
  getProjectLinksPortalApi: {
    status: "idle",
    error: null,
  },
};

export const pageBuilderPortalSlice = createSlice({
  name: "pageBuilderPortal",
  initialState,
  reducers: {
    setPageBuilderState(state, action) {
      state.pageBuilder = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProjectLinksPortal.pending, (state, action) => {
        if (state.getProjectLinksPortalApi.status === "idle") {
          state.getProjectLinksPortalApi.status = "pending";
          state.isLoading = true;
        }
      })
      .addCase(getProjectLinksPortal.fulfilled, (state, action) => {
        if (state.getProjectLinksPortalApi.status === "pending") {
          state.isLoading = false;

          state.pageBuilder = action.payload;
          state.page_data = action.payload?.page_data;
          state.total_projects = action.payload?.total_projects;
          state.contactPageBuilder.paginationData = action.payload.page_data;

          state.contactPageBuilder.projects = [];
          action.payload.projects.forEach((item) => {
            if (item.receiver_contact_id) {
              state.contactPageBuilder.projects.push(item);
            }
          });
          state.getProjectLinksPortalApi.status = "idle";
        }
      })
      .addCase(getProjectLinksPortal.rejected, (state) => {
        if (state.getProjectLinksPortalApi.status === "pending") {
          state.getProjectLinksPortalApi.status = "idle";
          state.isLoading = false;
        }
      })
      .addCase(searchUrl.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchUrl.fulfilled, (state, action) => {
        state.isLoading = false;

        state.pageBuilder = action.payload.data.data;
      })
      .addCase(searchUrl.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(updatePageBuilderPortalData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePageBuilderPortalData.fulfilled, (state, action) => {
        state.isLoading = false;

        state.pageBuilder = action.payload;
      })
      .addCase(updatePageBuilderPortalData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const {
  setPageBuilderState,
  setUiView,
  setEditView,
  reArrangeArray,
  setUiBlocksView,
  setSelectedUiBlock,
  setUIBlocksIndex,
  handleUpdateProfileBlock,
  updatePageBuilderData,
  handleAddOrRemoveUiBlocks,
  handleSocialMediaLinks,
  reArrangeSocialMediaIcons,
  handleSocialMediaLinksInput,
  handleSocialMediaTextInput,
  handleUpdatePageBuilderData,
  handleUpdatePageBuilderModuleData,
} = pageBuilderPortalSlice.actions;
export const pageBuilderPortalReducer = pageBuilderPortalSlice.reducer;
