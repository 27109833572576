import Api from "@/http/axiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const postPageBuilder = createAsyncThunk(
  "pageBuilderPortal/postPageBuilder",
  async (project_data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/pagebuilder/project-link`,
        {
          project_data,
        }
      );
      if (response.data) {
        // dispatch(createQRCode(response.data?.data?.id));
      }
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postDuplicatePageBuilder = createAsyncThunk(
  "pageBuilderPortal/postDuplicatePageBuilder",
  async (project_data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/pagebuilder/copy-project-data`,
        {
          project_data,
        }
      );

      // dispatch(createQRCode(response.data?.data?.id));

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const searchUrl = createAsyncThunk(
  "pageBuilderPortal/searchUrl",
  async (term, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/pagebuilder/project-links?page=1&search=${term}&records_per_page=`
      );

      return { data: response.data, headers: {} };
    } catch (error) {
      console.error("Error fetching URL shorteners:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// export const getProjectLinksPortal = createAsyncThunk(
//   "pageBuilderPortal/getProjectLinksPortal",
//   async (data, { rejectWithValue, dispatch }) => {
//     data = _.isUndefined(data) ? { page: 1, searchTerm: "" } : data;

//     try {
//       const response = await Api.get(
//         `${API_URL}/api/pagebuilder/project-links?page=` +
//           data.page +
//           "&is_portal=true" +
//           "&search=" +
//           data.searchTerm +
//           "&records_per_page=" +
//           (data.records_per_page ? data.records_per_page : "") +
//           (data.clientId ? "&client_id=" + data.clientId : "")
//       );

//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );
export const getProjectLinksPortal = createAsyncThunk(
  "pageBuilderPortal/getProjectLinksPortal",
  async (data = { page: 1, searchTerm: "" }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("page", data?.page || 1);
      params.append("is_portal", true);
      params.append("search", data?.searchTerm || "");
      if (data.records_per_page)
        params.append("records_per_page", data.records_per_page);
      if (data.clientId) params.append("client_id", data.clientId);

      const response = await Api.get(
        `${API_URL}/api/pagebuilder/project-links?${params.toString()}`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createQRCode = createAsyncThunk(
  "pageBuilderPortal/createQRCode",
  async (entityId, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/qr-code/pagebuilder/${entityId}`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePageBuilderPortalData = createAsyncThunk(
  "pageBuilderPortal/updateBulkPageBuilder",
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/pagebuilder/project-link/${id}`,
        payload
      );

      // dispatch(createQRCode(response.data?.data?.id));

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deletePageBuilder = createAsyncThunk(
  "pageBuilderPortal/deletePageBuilder",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`${API_URL}/api/pagebuilder/${id}`);

      // dispatch(createQRCode(response.data?.data?.id));

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPageBuilderQr = createAsyncThunk(
  "pageBuilderPortal/getPageBuilderQr",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/qr-code/pagebuilder/${id}`
      );

      return response.data.data;
    } catch (error) {
      if (error.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
